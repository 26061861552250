// Colors
$ps-border-radius: 4px !default;

$ps-rail-default-opacity: 0 !default;
$ps-rail-container-hover-opacity: 0.6 !default;
$ps-rail-hover-opacity: 0.9 !default;

$ps-bar-bg: transparent !default;
$ps-bar-container-hover-bg: #aaa !default;
$ps-bar-hover-bg: #999 !default;
$ps-rail-hover-bg: #eee !default;

// Sizes
$ps-scrollbar-x-rail-bottom: 3px !default;
$ps-scrollbar-x-rail-height: 8px !default;
$ps-scrollbar-x-bottom: 0 !default;
$ps-scrollbar-x-height: 8px !default;

$ps-scrollbar-y-rail-right: 3px !default;
$ps-scrollbar-y-rail-width: 8px !default;
$ps-scrollbar-y-right: 0 !default;
$ps-scrollbar-y-width: 8px !default;
