// ================================================================================================
//     File Name: coming-soon.scss
//     Description: Coming Soon pages custom scss
//     ----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 5 Admin Dashboard Template
//    Version: 3.0
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

/*========== Coming Soon Background Image =========*/
.comingsoonVideo .app-content .coming-soon-content {
    z-index: 99;
}

.clockCard {
    float: left;
    border-radius: .50rem;
}

.comingsoonOne {
    background: url(/assets/images/backgrounds/bg-12.jpg) no-repeat;
    background-size: cover;
}

.getting-started {
    font-size: 2.4rem;
    border-radius: 60px;
    min-width: 360px;
}

.countdownFormat {
    font-size: 16px;
    padding-bottom: 0px;
}

.socialIcon i {
    font-size: 28px;
    padding: 5px;
}

/*========== Coming Soon Flat Design =========*/

.clockFormat {
    border-radius: 60px;
}

.comingsoonFlat {
    background: url(/assets/images/backgrounds/bg-14.jpg) no-repeat;
    background-size: cover;
}
