// ================================================================================================
//   File Name: pallette-callout.scss
//   Description: callout color system styles
//   ----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 5 Admin Dashboard Template
//    Version: 3.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================


@function color-function($color, $type) {
  @if map-has-key($colors, $color) {
    $curr_color: map-get($colors, $color);

    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }

  // @warn "Unknown `#{name}` in $colors.";
  @return null;
}

// Color palettes
@import "palette-variables";

@import "../../core/mixins/callout";

@each $color_name,
$color in $colors {

  @each $color_type,
  $color_value in $color {
    @if $color_type=="base" {
      .bs-callout-#{$color_name} {
        @include bs-callout($color_value, lighten($color_value, 32%));

        &.callout-transparent {
          @include bs-callout($color_value, transparent);
        }

        .callout-arrow-left:before {
          border-left-color: $color_value;
        }

        .callout-arrow-right:before {
          border-right-color: $color_value;
        }
      }
    }

    @else {
      .climacon-#{$color_name}.climacon-#{$color_type} {
        .climacon_component-stroke {
          fill: $color_value;
        }
      }
    }
  }
}
